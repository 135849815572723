import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
function getTotalAmount (item) {
  const totalset = item.map(o => o.amount).reduce((a, c) => {
    a = a ? parseInt(a) : 0
    c = c ? parseInt(c) : 0
    return a + c
  })
  return totalset
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
              body: [
                [
                  { text: vm.$t('movement.program_no'), style: 'td', alignment: 'right' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$n(data.program_no, { useGrouping: false }), style: 'td', alignment: 'left' },
                  { text: vm.$t('globalTrans.date'), style: 'td', alignment: 'right' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: dateFormat(data.transfer_date), style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('movement.from_godown'), style: 'td', alignment: 'right' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: (i18n.locale === 'bn') ? data.godown_name_bn : data.godown_name, style: 'td', alignment: 'left' },
                  { text: vm.$t('movement.fertilizer_name'), style: 'td', alignment: 'right' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: (i18n.locale === 'bn') ? data.fertilizer_name_bn : data.fertilizer_name, style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.to_godown'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.transferred_amount'), style: 'th', alignment: 'right' },
            { text: vm.$t('movement.transport_agent'), style: 'th', alignment: 'center' }
          ]
        ]
        data.inter_godown_details.map((details, index) => {
          allRows.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? details.to_godown_bn : details.to_godown, style: 'td', alignment: 'center' },
            { text: vm.$n(details.amount), style: 'td', alignment: 'right' },
            { text: (i18n.locale === 'bn') ? details.transport_agent_bn : details.transport_agent, style: 'td', alignment: 'center' }
          ]
          )
        })
        var tablefooter = [
          { text: vm.$t('movement.total'), colSpan: '2', style: 'td', alignment: 'right', bold: true },
          {},
          { text: vm.$n(getTotalAmount(data.inter_godown_details)), style: 'td', alignment: 'right', bold: true },
          {}
        ]
        allRows.push(tablefooter)
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '25%', '30%', '35%'],
            body: allRows
          }
        })

        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          fertilizer: {
            margin: [0, 5, 0, 5]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('inter-godown-program-management')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
