<template>
  <b-container>
    <b-overlay :show="loading">
      <!-- <b-row class="p-3">
        <b-col class="text-right">
          <b-button @click="pdfExport()" class="btn btn-primary">
            <i class="ri-file-pdf-line"></i> {{  $t('globalTrans.export_pdf') }}
          </b-button>
        </b-col>
      </b-row> -->
    <b-row class="mb-5" >
      <b-col lg="10" md="10" sm="12" offset-lg="1" offset-md="1" style="border: 1px solid gray">
        <div class="mt-5 mb-3">
          <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
            {{ $t('movement.inter_godown_fertilizer_shipment_challan') }}
          </list-report-head>
        </div>
        <table class="table table-sm table-bordered">
          <tbody>
            <tr>
              <td>{{ $t('movement.program_no') }} </td>
              <td class="text-center">:</td>
              <td>{{ $n(formData.program_no, { useGrouping: false }) }}</td>
              <td>{{ $t('globalTrans.date') }} </td>
              <td class="text-center">:</td>
              <td>{{ formData.transfer_date | dateFormat }}</td>
            </tr>
            <tr>
              <td>{{ $t('movement.from_godown') }} </td>
              <td class="text-center">:</td>
              <td>{{  currentLocale === 'bn' ? formData.godown_name_bn : formData.godown_name }}</td>
              <td>{{ $t('movement.fertilizer_name') }} </td>
              <td class="text-center">:</td>
              <td>{{  currentLocale === 'bn' ? formData.fertilizer_name_bn : formData.fertilizer_name }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <u class="text-dark"><p class="table-head">{{ $t('movement.to_godown') + ' ' + $t('movement.list') }}</p></u> -->
        <b-row>
          <div class="col text-right">
            <span class="text-right text-dark  font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
          </div>
        </b-row>
        <table class="table table-bordered table-sm table-hover mb-5">
          <tbody>
            <tr>
              <td class="text-center">{{ $t('globalTrans.sl_no') }}</td>
              <td class="text-center">{{ $t('movement.to_godown') }}</td>
              <td class="text-right">{{ $t('movement.transferred_amount') }}</td>
              <td class="text-center">{{ $t('movement.transport_agent') }}</td>
            </tr>
            <tr v-for="(details, index) in formData.inter_godown_details" :key='index'>
              <td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</td>
              <td class="text-center">{{  currentLocale === 'bn' ? details.to_godown_bn : details.to_godown }}</td>
              <td class="text-right">{{ $n(details.amount, { useGrouping: false }) }}</td>
              <td class="text-center">{{  currentLocale === 'bn' ? details.transport_agent_bn : details.transport_agent }}</td>
            </tr>
            <tr :key='"total"'>
              <th class="text-right" colspan="2">{{ $t('movement.total') }}</th>
              <th class="text-right">{{ $n(getTotalAmount(formData.inter_godown_details)) }}</th>
              <th></th>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '../../../fertilizer-reports/pages/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getInterGodownData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      formData: {},
      loading: false
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    transportAgentList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    }
  },
  methods: {
    getTotalAmount (item) {
      const totalset = item.map(o => o.amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
      return totalset
    },
    pdfExport () {
      const reportTitle = this.$t('movement.inter_godown_program_management')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this)
    },
    getInterGodownData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return this.getCustomDataList(JSON.parse(JSON.stringify(tmpData)))
    },
    getCustomDataList (data) {
      const listData = data.inter_godown_details.map(item => {
        const transportObj = this.transportAgentList.find(doc => doc.value === item.transport_agent_id)
        const transportData = {}
        if (typeof transportObj !== 'undefined') {
          transportData.transport_agent = transportObj.text_en
          transportData.transport_agent_bn = transportObj.text_bn
        } else {
          transportData.transport_agent = ''
          transportData.transport_agent_bn = ''
        }

        const toGodownObj = this.godownInfoList.find(doc => doc.value === item.to_godown_infos_id)
        const toGodownData = {}
        if (typeof toGodownObj !== 'undefined') {
          toGodownData.to_godown = toGodownObj.text_en
          toGodownData.to_godown_bn = toGodownObj.text_bn
        } else {
          toGodownData.to_godown = ''
          toGodownData.to_godown_bn = ''
        }
        return Object.assign({}, item, transportData, toGodownData)
      })
      return Object.assign({}, data, { inter_godown_details: listData })
    }
  }
}
</script>
<style scoped>
 .table-head {
    font-weight: bold;
    color: black;
    font-size: 16px;
    padding: 0;
    margin-bottom: 5px !important;
 }
</style>
